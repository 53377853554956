/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components), {StateCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "everything-to-know-about-medical-grade-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#everything-to-know-about-medical-grade-hearing-aids",
    "aria-label": "everything to know about medical grade hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Everything to know about medical-grade hearing aids"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "basic-hearing-aid-functionality",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-hearing-aid-functionality",
    "aria-label": "basic hearing aid functionality permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic Hearing Aid Functionality"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids receive sound from the outside world just as a healthy human ear does. Aids have microphones in them, which change sounds into vibrations and then into electrical impulses. At this point, the signals are usually weak and need to be amplified before they can be changed into something that device wearers can actually hear. Amplifiers inside the device turn quiet noises into signals that can then be turned into audible sound."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "Hearing aids"), " also have a speaker. They turn the amplified signals into sound. The actual sound level can typically be controlled, depending on the outside environment and the user’s degree of hearing loss. Many devices can also be calibrated to amplify low or high-frequency sounds."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-styles",
    "aria-label": "hearing aid styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Styles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/ite/",
    className: "c-md-a"
  }, "In-the-ear hearing aids"), " (ITE) are nearly invisible. They rest inside the ear canal. Since they are so small, ITE devices are best suited for those with mild to severe hearing loss. The amount of space in your auditory canal also influences if you can use ITEs or not."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/bte/",
    className: "c-md-a"
  }, "Behind-the-ear hearing aids"), " (BTE) are discreet but high-performing. They have a sound tube with a customized earmold or external receiver that is inside the ear canal. BTEs are suitable for mild to profound hearing loss. This device is available in multiple sizes and styles. Selection will depend on your preferred battery life and performance."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "modern-technology-in-devices",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modern-technology-in-devices",
    "aria-label": "modern technology in devices permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modern Technology in Devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all of the progress made in ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "technology"), ", there is a wide selection of hearing aid models available for different lifestyles and levels of hearing loss. They do not simply capture sounds better, they can change the user’s connection to the world around them. They can improve someone’s participation in auditory life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "New hearing aid models can be considered miniature supercomputers. They use advanced algorithms to process sounds and deliver crystal-clear sound to the wearer. Some hearing aids even have the capability to track brain and body movements."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "available-hearing-aid-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#available-hearing-aid-technology",
    "aria-label": "available hearing aid technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Available Hearing Aid Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Essential technology is suited for people who spend most of their time at home. They are good for one-on-one conversations. Devices at this level provide good amplification and feedback cancellation in quiet settings. Their basic features allow you to hear the TV at normal volume and to understand your conversation partner clearly in small groups."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Advanced technology is suited for those who live a more active lifestyle. This level provides good sound quality in complex listening situations. Advanced devices perform well in auditoriums, family gatherings, sporting events, and restaurants. Many of the features are also automated, which removes the constant need to manually adjust settings. Two examples of these features are adaptive directional microphones and automatic noise reduction."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Premium technology devices offer more advanced features. They are a good option for both active and sedentary lifestyles. They provide HD sound quality, directional microphones, acoustic motion sensors, digital noise/feedback reduction, and moisture resistance. Many are also ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "rechargeable"), ", so you won’t have to manage any tiny batteries. Premium technology often comes equipped with ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth"), " and smartphone connectivity. This enables you to control the settings and to wirelessly stream music and phone calls directly to your hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-bells-and-whistles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-bells-and-whistles",
    "aria-label": "the bells and whistles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Bells and Whistles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The higher-level technologies are in no way frivolous. They can improve the experience of everyday life for those with a hearing impairment; hear.com recommends getting the best hearing aid that you can afford."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth connectivity makes staying connected to the world around you easier. Just like the expensive brands of wireless headphones out there, medical-grade hearing aids now have the power to stream your favorite TV shows, podcasts, music, and phone calls directly into your ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Intuitive smartphone apps are also available. These controls give you the ability to change your hearing aid volume or program to match your environment. For example, if you’re going from your house to a crowded restaurant, you’ll probably want to adjust the levels. You can do that quickly from your iPhone or Android phone without even touching your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rechargeable batteries are becoming increasingly popular. They can even come with portable charging cases for added security and convenience. They are environmentally-friendly and cheaper than buying batteries forever. Charging devices now make it easier to keep your hearing aids functioning all day without interruption. An obvious bonus is no longer having to pinch, poke, and potentially lose tiny batteries."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Today’s medical-grade hearing aids have dramatically improved speech clarity and understanding. Improved speech clarity has always been the most desired feature of hearing aid users. Hearing loss is actually a deficit with speech clarity, not volume, and older hearing aids weren’t very effective. Advanced features now incorporate narrow directionality, echo adjustments, and spatial configurators to deliver the highest level of speech clarity even in demanding situations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Background noise makes it difficult to follow a conversation and understand each word. A lot of auditory stimulation happening in one spot can make speech harder to process, no matter what age you are or what degree of hearing loss you have. That’s why new hearing aids have sophisticated algorithms that can identify background noise and filter it out. This includes annoying feedback and “whistles” that used to occur with old devices. The background noise reduction feature can reduce your listening effort and save you some energy!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/background-noise.png",
    alt: "Person having a hard time hearing due to background noise, too many people talking",
    className: "c-md-img"
  }), "\n", React.createElement(_components.em, null, "Background noise can be difficult for those with hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Augmented Focus technology creates better processing of background noise and speech in hearing aids. In Audiology Online, the method is further described. Two independent processors handle the “focus” sounds like speech and the “surrounding” sounds like music or laughter. The difference of those two sources is increased, and the “surrounding” sounds are perceived as being further away. Augmented Focus was shown to bring 25% better speech understanding compared to standard hearing aids. Another 95% of users reported exceptional speech understanding within their home. Because of the success of this new technology, we’ve incorporated this into our newest hearing aid, the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go IX"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Acoustic and motion sensors now help to process sounds no matter what environment you are in. Directional and Open 360° Sound help the brain understand sound directionality so you hear in the most natural way possible. This helps the user determine exactly where traffic is coming from, or where an emergency vehicle is headed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With automatic environment detection, hearing aids can scan your surroundings over 10,000 times a second and take your movement into consideration. They automatically adjust their programs to deliver an optimal sound experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Own Voice Processing means your device can instantly recognize your speech and independently process it. This allows you to hear yourself naturally even in a noisy room."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Binaural processing enables both hearing aids to communicate wirelessly and mimic the brain’s natural ability to process information from both ears. A single hearing aid cannot provide the same level of hearing quality that two devices can. So, yes, you do need a hearing aid even for the “good” ear!"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Try our Medical hearing aids now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
